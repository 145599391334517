import { useTranslation } from 'react-i18next';
import { CoverStack, CoverStackSpecialist, useMediaQuery, getMinWidthMediaQuery } from '@beauty/beauty-market-ui';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { Organisation } from '../../Organisation/types';
import { StatePanelWrapper, StatePanelInfo } from '../style';
import SelectedService from './SelectedService';
import SelectedSpecialist from './SelectedSpecialist';
import SelectedTime from './SelectedTime';

type StatePanelType = {
  organisation: Organisation;
};

const StatePanel = ({ organisation }: StatePanelType) => {
  const { t } = useTranslation();
  const { booking } = useAppSelector(selectUser);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const isStatePanelVisible = (booking.service || booking.timeslot) && isDesktop;

  const coverStackProps = {
    specialistType: `${t(organisation.offers.categories.map(cat => cat.name).join(', '))}`,
    specialistLabel: organisation.name,
    rating: organisation.rating,
    imgUrl: organisation.mainPhoto,
  };

  const content = (
    <StatePanelInfo>
      <SelectedService />
      <SelectedTime />
      <SelectedSpecialist />
    </StatePanelInfo>
  );

  return (
    isStatePanelVisible && (
      <StatePanelWrapper>
        <CoverStack header={<CoverStackSpecialist {...coverStackProps} />} width="100%" content={content} />
      </StatePanelWrapper>
    )
  );
};

export default StatePanel;
