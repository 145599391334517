import { useTranslation } from 'react-i18next';
import { BodyLarge, H3, ShowMore } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { getTranslation } from '../../../helpers/utils';
import {
  CategoryType,
  Property,
  ReviewType,
  SpecialRequirementType,
  TeamMateType,
  TextWithTranslations,
} from '../../../types';
import { AboutAdvantages, AboutAdvantagesItem, AboutWrapper, Description } from '../style';
import Offerings from './Offerings';
import PhotoGallery from './PhotoGallery';
import Team from './Team';

interface AboutProps {
  description: string;
  organisationName: string;
  photo: string[];
  offers: {
    categories: CategoryType[];
    specialRequirement?: SpecialRequirementType;
  };
  reviews?: ReviewType[];
  orgId: string;
  team?: TeamMateType[];
  orgDescr?: TextWithTranslations;
  property: Property[];
}

const About = (props: AboutProps) => {
  const { t } = useTranslation();
  const { property, description, orgDescr, team, offers, organisationName, orgId, photo } = props;
  const language = getSelectedLanguage();

  return (
    <AboutWrapper>
      <H3>{t('organisation.about.about')}</H3>
      <AboutAdvantages>
        {property.map(prop => (
          <AboutAdvantagesItem key={prop.id}>
            <img src={prop.iconUrl} alt={prop.title} />
            <BodyLarge large>{prop.title}</BodyLarge>
          </AboutAdvantagesItem>
        ))}
      </AboutAdvantages>
      <Description>
        <ShowMore
          text={orgDescr ? getTranslation(orgDescr, language) : description}
          visibleLines={3}
          showMore={t('organisation.about.showMore')}
          showLess={t('organisation.about.showLess')}
        />
      </Description>
      {!!photo?.length && <PhotoGallery photos={photo} />}
      <Offerings {...offers} organisationName={organisationName} />
      {!!team?.length && <Team team={team} orgId={orgId} orgName={organisationName} />}
    </AboutWrapper>
  );
};

export default About;
