import { useTranslation } from 'react-i18next';
import { BodyLarge, Flex, ListRegionCode } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../../../../components/functional/formik/formik-input/FormikInput';
import { getListRegionCode } from '../../../../../../helpers/utils';
import { ProfileFormFields } from '../../../Profile.definitions';
import { WrapperInput } from './style';

type ContactsFormProps = {
  setCode: (code: string) => void;
  initialCode: string;
  code?: number;
  phone?: string;
  email?: string;
};

const ContactsForm = ({ initialCode, phone = '', email = '', setCode }: ContactsFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyLarge large mb="16px">
        {t('profile.contacts')}
      </BodyLarge>
      <Flex flexDirection="column">
        <Flex mb="16px" width="100%" justifyContent="space-between" gap="8px">
          <ListRegionCode
            design="white"
            backgroudColor="white"
            id={ProfileFormFields.Region}
            name={ProfileFormFields.Region}
            currentRegionCode={initialCode}
            placeholder={t('profile.region')}
            options={getListRegionCode()}
            handleSelect={setCode}
            disabled
          />

          <WrapperInput>
            <FormikInput
              design="white"
              id={ProfileFormFields.Phone}
              name={ProfileFormFields.Phone}
              placeholder={t('profile.phone')}
              disabled
            />
          </WrapperInput>
        </Flex>
        <FormikInput
          design="white"
          id={ProfileFormFields.Email}
          name={ProfileFormFields.Email}
          placeholder={t('profile.email')}
          mb="16px"
          disabled
        />
      </Flex>
    </>
  );
};

export { ContactsForm };
