import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uniq from 'lodash/uniq';
import { Nullable } from 'tsdef';
import { EventCard, Flex, H6, H3, useNotify, NotifyStatus } from '@beauty/beauty-market-ui';
import { RatingSidebar, RatingSidebarProps } from '../../../../components/RatingSidebar/RatingSidebar';
import { getSelectedLanguage } from '../../../../constants';
import { getFullDate, setTitle, updateAppointment } from '../../../../helpers/appointments';
import { getAppointmentNotifyContent } from '../../../../helpers/notifyContent/appointment';
import { getTranslation } from '../../../../helpers/utils';
import { useMediaScreen } from '../../../../hooks';
import { useGetOrganisation } from '../../../../hooks/useGetOrganisation';
import { RouterUrl } from '../../../../routes/routes';
import { useAppSelector } from '../../../../store/hooks';
import { setAppointment } from '../../../../store/redux-slices/appointmentsSlice';
import { selectOrganisation } from '../../../../store/redux-slices/organisationSlice';
import { selectUser, updateBooking, updateUser } from '../../../../store/redux-slices/userSlice';
import { AppointmentActions, AppointmentType } from '../../../../types/appointment';
import { SpecialistType } from '../../../../types/specialist';
import { getFormattedCurrency } from '../../../Organisation/helpers';
import { EventStatus } from '../constants';
import { ButtonsWrapper, CardWrapper, PriceWrapper, StyledButton, StyledSeparator } from '../style';
import { TodoType } from '../types';
import { Organisation } from './Organisation';
import { PopupBlock } from './PopupBlock';

type EventInfoProps = {
  event: AppointmentType;
  toReview?: boolean;
};

const EventInfo = ({ event, toReview = false }: EventInfoProps) => {
  const [isPopup, setIsPopup] = useState(false);
  const [isSendPopup, setIsSendPopup] = useState(false);
  const [whatToDo, setWhatToDo] = useState<TodoType | null>(event.status === EventStatus.PAST ? 'manage' : null);
  const [reviewAppointmentOpen, setReviewAppointmentOpen] = useState(toReview);
  const [appointmentDetails, setAppointmentDetails] = useState<Nullable<RatingSidebarProps>>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notify = useNotify();

  const user = useAppSelector(selectUser);
  const { city, street, building, office, postal } = event.orgService.organization.address.address[0];
  const address = [city, street, building, office, postal];

  const { isMobile } = useMediaScreen('md');

  const { orgId } = event.orgService;
  const { organisation: organisationFromBE } = useGetOrganisation(orgId);
  const { organisation } = useAppSelector(selectOrganisation);
  const orgAddress = organisation?.address.address[0];

  const language = getSelectedLanguage();
  const { categoryId } = event.orgService.headOrgService;
  const serviceId = event.orgServId;

  const currentState = {
    organisationFromBE,
    service: organisationFromBE
      ? organisationFromBE.offers.categories
          .find(category => category.id === categoryId)
          ?.services.find(service => service.id === serviceId)
      : null,
  };

  const specialist = useMemo(
    () => organisation?.orgSpecialist.find(spec => spec.orgSpecId === event.orgSpecId),
    [organisation, event],
  );

  const cardStatus = useMemo(() => {
    if (event.status === EventStatus.INPROGRESS) return EventStatus.CONFIRMED;
    if ([EventStatus.CANCELLED, EventStatus.NOSHOW].includes(event.status)) return EventStatus.CANCELLED;
    if (event.status === EventStatus.UNCLOSED) return EventStatus.PAST;
    return event.status;
  }, [event.status]);

  const toggleReviewAppointment = () => {
    setReviewAppointmentOpen(!reviewAppointmentOpen);
  };

  const updateBooked = () => {
    dispatch(
      updateBooking({
        service: organisationFromBE
          ? organisationFromBE.offers.categories
              .find(category => category.id === categoryId)
              ?.services.find(service => service.id === serviceId)
          : null,
        specialist: organisationFromBE?.team
          ? (organisationFromBE?.team.find(teammate => teammate.id === event.orgSpecId) as SpecialistType)
          : null,
      }),
    );
    dispatch(updateUser({ ...user, selectedAppointmentId: event.id }));
  };

  const handleCancelAppointment = async () => {
    const response = await updateAppointment(event.id, { status: EventStatus.CANCELLED });
    if (response?.status === 200) {
      notify(getAppointmentNotifyContent(NotifyStatus.SUCCESS, AppointmentActions.Cancel, t));
      dispatch(setAppointment({ id: event.id, data: { ...event, status: EventStatus.CANCELLED } }));
    } else {
      notify(getAppointmentNotifyContent(NotifyStatus.ERROR, AppointmentActions.Cancel, t));
    }
  };

  const handleRebookAppointment = () => {
    updateBooked();
    navigate(`${RouterUrl.Booking}/${orgId}`, {
      state: currentState,
    });
  };

  const handleRescheduleAppointment = () => {
    updateBooked();
    navigate(`${RouterUrl.Booking}/${orgId}`, {
      state: { ...currentState, timeslotId: event.start },
    });
  };

  const getSpecificButton = (action: 'cancel' | 'manage' | 'rebook', isDouble = false) => (
    <StyledButton
      width="100%"
      design={isDouble && action === 'manage' ? 'primary' : 'quaternary'}
      // mr={isDouble && action === 'cancel' ? [0, 0, 0, '16px'] : 0}
      onClick={() => {
        if (action === 'rebook') {
          handleRebookAppointment();
        } else {
          setWhatToDo(action);
          setIsPopup(true);
        }
      }}
    >
      {language !== 'RU' || action !== 'rebook'
        ? `${t(`docs.${action}`)} ${t(`appointments.${action === 'manage' ? 'event' : 'appointment'}`)}`
        : `${t('appointments.rebook')}`}
    </StyledButton>
  );

  const setHandleButtons = (status: EventStatus) => {
    switch (status) {
      case EventStatus.CANCELLED:
      case EventStatus.NOSHOW:
        return getSpecificButton('rebook');
      case EventStatus.PAST:
      case EventStatus.UNCLOSED:
        return getSpecificButton('manage');
      case EventStatus.CONFIRMED:
      case EventStatus.PENDING: {
        return (
          <Flex flexDirection={['column', 'column', 'column', 'row']} gap={isMobile ? '8px' : '24px'} width="100%">
            {getSpecificButton('cancel', true)}
            {getSpecificButton('manage', true)}
          </Flex>
        );
      }
      default:
        return <div />;
    }
  };

  useEffect(() => {
    const { name, address: eventOrgAddress } = event.orgService.organization;
    setAppointmentDetails({
      appointmentId: event.id,
      date: getFullDate(event.start, t, language),
      address: {
        orgName: name,
        info: eventOrgAddress.address[0].fullAddress,
        url: organisation?.mainPhoto?.url || '',
      },
      specialist: specialist
        ? {
            orgSpecId: '',
            name: specialist ? `${specialist.name} ${specialist.surname}` : '',
            surname: '',
            avatarUrl: specialist.avatarUrl,
            specialization: specialist.specialization,
            rating: specialist.rating,
          }
        : undefined,
    });
  }, [event.id, organisation, language]);

  return (
    <Flex flexDirection="column" width="100%" mt={['20px', '20px', '20px', 0]}>
      <Organisation
        info={{
          address,
          icon: organisation?.mainPhoto?.url || '',
          category:
            uniq(organisation?.category.map(cat => getTranslation(cat.parent?.title, language))).join(', ') || '',
          name: event.orgService.organization.orgName
            ? getTranslation(event.orgService.organization.orgName, language)
            : event.orgService.organization.name,
          coordinates: orgAddress ? { lat: orgAddress.lat, lng: orgAddress.lng } : null,
        }}
        id={orgId}
      />

      <CardWrapper>
        <EventCard
          title={t('appointments.service')}
          status={cardStatus}
          statusTitle={setTitle(cardStatus, t)}
          label={getTranslation(event.orgService.headOrgService.title, language)}
          placeholder={getFullDate(event.start, t, language)}
          isPlaceholderBlack
          isExtended
          onClick={() => null}
        />
      </CardWrapper>

      <StyledSeparator />

      <PriceWrapper>
        <H6>{t('appointments.price')}</H6>
        <H3>
          {event.orgService.price
            ? getFormattedCurrency(event.orgService.price, organisation?.headOrganization?.currency, language)
            : t('organisation.booking.nullPrice')}
        </H3>
      </PriceWrapper>

      <ButtonsWrapper>{setHandleButtons(event.status)}</ButtonsWrapper>
      {isPopup && (
        <PopupBlock
          event={event}
          whatToDo={whatToDo}
          isSendPopup={isSendPopup}
          handleCancelAppointment={handleCancelAppointment}
          handleRebookAppointment={handleRebookAppointment}
          handleRescheduleAppointment={handleRescheduleAppointment}
          handleAddToCalendar={() => window.open('https://calendar.google.com/calendar/u/0/r?pli=1', '_blank')}
          setIsPopup={setIsPopup}
          setIsSendPopup={setIsSendPopup}
          handleWriteReview={toggleReviewAppointment}
          handleSendReceipt={() => {
            setWhatToDo('send');
            setIsSendPopup(false);
          }}
        />
      )}

      {reviewAppointmentOpen && appointmentDetails && (
        <RatingSidebar {...appointmentDetails} setOpen={setReviewAppointmentOpen} />
      )}
    </Flex>
  );
};

export { EventInfo };
