import { useTranslation } from 'react-i18next';
import { Button, CloseIcon, DataList, useMediaQuery, getMinWidthMediaQuery } from '@beauty/beauty-market-ui';
import { BookingPopup } from '../../../components';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { StyledArrowLeftIcon } from '../style';
import CustomBottomSheet from './CustomBottomSheet/CustomBottomSheet';

export interface PopupProps {
  showTimeslots: boolean;
  onPrimaryBtnClick: () => void;
  onSecondaryBtnClick: () => void;
  disabled?: boolean;
}

const Popup = ({ showTimeslots, onPrimaryBtnClick, onSecondaryBtnClick, disabled }: PopupProps) => {
  const { booking } = useAppSelector(selectUser);
  const { t } = useTranslation();
  // const { specialist, timeslot } = booking;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const primaryButton = showTimeslots ? (
    <Button size="large" width="290px" onClick={onPrimaryBtnClick} disabled={!(booking.service && booking.timeslot)}>
      {t('organisation.booking.goToCheckout')}
    </Button>
  ) : (
    <Button size="large" width="290px" onClick={onPrimaryBtnClick}>
      {t('organisation.booking.dateOfVisit')}
    </Button>
  );

  const secondaryButton = (
    <Button design="secondary" onClick={onSecondaryBtnClick}>
      {showTimeslots ? <StyledArrowLeftIcon height="22" width="22" /> : <CloseIcon height="22" width="22" />}
    </Button>
  );

  // TODO BottomSheet is removed due to Vadim Musnik request 08.07.23
  const mobileContent = booking.service && (
    <>
      <DataList
        label={booking.service.title}
        description={booking.service.description}
        oldPrice={booking.service.oldPrice}
        price={booking.service.price}
      />
      {/* {timeslot && <DataList label={timeslot.startTime} description={timeslot.date} />}
      {specialist && (
        <DataList
          description={
            <Flex alignItems="center">
              <Avatar size="xs" url={specialist.photo} />
              <BodySmall ml="16px">{specialist.name}</BodySmall>
            </Flex>
          }
        />
      )} */}
    </>
  );

  const mobileFooter = (
    <>
      {secondaryButton}
      {primaryButton}
    </>
  );

  return isMobile && !showTimeslots ? (
    <CustomBottomSheet content={mobileContent} footer={mobileFooter} />
  ) : (
    <BookingPopup primaryButton={primaryButton} secondaryButton={secondaryButton} disabled={disabled} />
  );
};

export default Popup;
