import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H5, Slider } from '@beauty/beauty-market-ui';
import { FullImage } from '../../../components';
import { isRtl } from '../../../helpers/rtl';
import { useFullImage } from '../../../hooks/useFullImage';
import { GalleryImage } from '../style';

interface PhotoGalleryProps {
  photos: string[];
}

const getImages: (slides: string[], setImage: (src: string) => void) => JSX.Element[] = (slides, setImage) =>
  slides.map(slide => <GalleryImage src={slide} key={slide} alt="slide" onClick={() => setImage(slide)} />);

const PhotoGallery = ({ photos }: PhotoGalleryProps) => {
  const { t } = useTranslation();
  const { isOpen, image, setImage, onClose } = useFullImage();
  const rtl = isRtl();

  const title = <H5>{t('organisation.about.photoGallery')}</H5>;
  const slides = useMemo(() => getImages(photos, setImage), [photos]);

  return (
    <>
      <Slider title={title} titleBottomMargin="24px" slides={slides} marginTop="64px" isPaginationEnabled rtl={rtl} />
      <FullImage isOpen={isOpen} src={image} onClose={onClose} />
    </>
  );
};

export default PhotoGallery;
