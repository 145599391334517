import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, H2, Caption } from '@beauty/beauty-market-ui';
import { AnchorMenu } from '../../components/AnchorMenu/AnchorMenu';
import { PrivacyWrapper, StyledContainer } from '../../style';
import { useScrollY } from '../HomePage/hooks/useScrollPosition';
import {
  HowToWork,
  Introduction,
  KeyTerms,
  OurPartnersServices,
  OurServices,
  PayingForPartners,
  Summary,
  UsingPlatform,
  Termination,
  General,
} from './components';
import { Changes } from './components/Changes';
import { termsOfServiceTitles } from './constants';

const sections = [
  Summary,
  Introduction,
  KeyTerms,
  OurServices,
  OurPartnersServices,
  UsingPlatform,
  HowToWork,
  PayingForPartners,
  Changes,
  Termination,
  General,
];

// TODO Refactor to enable eslint checks
export const TermsOfService = () => {
  const { t } = useTranslation();

  const [activeSection, setActiveSection] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);

  const titleRefs = useRef<Flex[]>(new Array(termsOfServiceTitles.length));

  const posY = useScrollY();

  useEffect(() => {
    const currentSection =
      termsOfServiceTitles.length - titleRefs.current.reverse().findIndex(ref => ref.getBoundingClientRect().y < 0) - 1;
    titleRefs.current.reverse();

    if (!isClicked) {
      setActiveSection(currentSection);
    }
  }, [posY, termsOfServiceTitles]);

  return (
    <StyledContainer>
      <PrivacyWrapper>
        <AnchorMenu
          menuItems={termsOfServiceTitles.map(title => t(`termsOfService.${title}.title`))}
          hrefs={titleRefs}
          activeSection={activeSection}
          onClick={setIsClicked}
        />

        <Flex width="100%" flexDirection="column" ml={['0', '0', '0', '38px']}>
          <H2 mb="8px">{t('termsOfService.title')}</H2>
          <Caption mb="-40px">{t('termsOfService.lastUpdated')}</Caption>
          <Flex flexDirection="column" maxWidth="620px">
            {sections.map((Section, index) => (
              <Section
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                ref={el => {
                  titleRefs.current[index] = el;
                }}
              />
            ))}
          </Flex>
        </Flex>
      </PrivacyWrapper>
    </StyledContainer>
  );
};
