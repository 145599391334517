import { H6, BodySmall, Flex } from '@beauty/beauty-market-ui';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';

const SelectedTime = () => {
  const { booking } = useAppSelector(selectUser);

  return booking.timeslot ? (
    <Flex gap="2px" flexDirection="column" justifyContent="center" width="inherit">
      <BodySmall>{booking.timeslot.date}</BodySmall>
      <H6>
        {booking.timeslot.start}-{booking.timeslot.end}
      </H6>
    </Flex>
  ) : null;
};

export default SelectedTime;
