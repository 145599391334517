import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import {
  FilterIcon,
  Flex,
  getMinWidthMediaQuery,
  H3,
  Link,
  Loader,
  Review,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { FullImage } from '../../../../components';
import { getSelectedLanguage } from '../../../../constants';
import { getFullDate } from '../../../../helpers/appointments';
import { getTranslation } from '../../../../helpers/utils';
import { useFullImage } from '../../../../hooks/useFullImage';
import { useGetOrganisationReviews } from '../../../../hooks/useGetOrganisationReviews';
import { ReviewType, TeamMateType } from '../../../../types/organisation';
import { FilterButton, ReviewsBodyWrapper, ReviewsList, ReviewsWrapper, ShowMoreButton } from '../../style';
import { DateSorting } from './DateSorting';
import { NoReview } from './NoReview';
import { RatingFilter } from './RatingFilter';
import { SpecialistFilter } from './SpecialistFilter';

interface ReviewsProps {
  orgId: string;
  selectedRating: Nullable<number>;
  setSelectedRating: (rating: Nullable<number>) => void;
  selectedDateSorting: Nullable<number>;
  setSelectedDateSorting: (type: Nullable<number>) => void;
  selectedSpecialist: Nullable<string>;
  setSelectedSpecialist: (specId: Nullable<string>) => void;
  setFilterOpen: (isFilterOpen: boolean) => void;
  team: TeamMateType[];
  sidebarReviews?: boolean;
  titleHidden?: boolean;
}

const Reviews = ({
  orgId,
  selectedRating,
  setSelectedRating,
  selectedDateSorting,
  setSelectedDateSorting,
  selectedSpecialist,
  setSelectedSpecialist,
  setFilterOpen,
  sidebarReviews = false,
  titleHidden,
  team,
}: ReviewsProps) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const [page, setPage] = useState(1);
  const [allReviews, setAllReviews] = useState<Nullable<ReviewType[]>>(null);

  const { image, setImage, isOpen, onClose } = useFullImage();
  const { isLoading, reviews, pages } = useGetOrganisationReviews(
    orgId,
    page,
    selectedDateSorting,
    selectedRating,
    selectedSpecialist,
  );

  const filteredReviews =
    allReviews?.map(review => (
      <Review
        key={`${review.createdAt}${review.service?.text}`}
        avatarUrl={review.client?.avatarUrl}
        username={
          review.client ? `${review.client?.name} ${review.client?.surname}` : `${t('organisation.rating.guest')}`
        }
        date={getFullDate(review.createdAt, t, language)}
        rating={+review.score}
        service={getTranslation(review.service, language)}
        specialist={`${review.specialist.name} ${review.specialist.surname}`}
        text={review.summary}
        photosUrls={review.photo}
        isSidebar={sidebarReviews}
        setSelectedPhoto={setImage}
      />
    )) ?? null;

  const reviewsBody = filteredReviews?.length ? (
    <ReviewsBodyWrapper>
      <ReviewsList>{filteredReviews}</ReviewsList>
    </ReviewsBodyWrapper>
  ) : (
    <NoReview />
  );

  const resetFilters = () => {
    setSelectedRating(null);
    setSelectedDateSorting(null);
    setSelectedSpecialist(null);
  };

  const onShowMore = () => setPage(prev => prev + 1);

  useEffect(() => {
    reviews && setAllReviews(prevAllReviews => (prevAllReviews ? [...prevAllReviews, ...reviews] : reviews));
  }, [reviews, setAllReviews]);

  useEffect(() => {
    setPage(1);
    setAllReviews(null);
  }, [selectedRating, selectedDateSorting, selectedSpecialist]);

  return (
    <ReviewsWrapper>
      <Flex alignItems="center">
        {!titleHidden && <H3 width={isDesktop ? '180px' : 'auto'}>{t('organisation.rating.reviews')}</H3>}
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          {isDesktop ? (
            <Flex gap="16px">
              <RatingFilter selectedOptionId={selectedRating} onSelect={setSelectedRating} />
              {((filteredReviews?.length && filteredReviews.length > 1) || isLoading) && (
                <DateSorting selectedOptionId={selectedDateSorting} onSelect={setSelectedDateSorting} />
              )}
              <SpecialistFilter team={team} selectedSpecId={selectedSpecialist} onSelect={setSelectedSpecialist} />
            </Flex>
          ) : (
            <FilterButton design="tertiaryBlack" size="small" onClick={() => setFilterOpen(true)}>
              <FilterIcon />
            </FilterButton>
          )}
          {(selectedRating || selectedDateSorting !== null || selectedSpecialist) && (
            <Link onClick={resetFilters}>{t('home.filterSidebar.reset')}</Link>
          )}
        </Flex>
      </Flex>
      {!isLoading || filteredReviews ? (
        reviewsBody
      ) : (
        <Flex height="150px" alignItems="center" justifyContent="center">
          <Loader />
        </Flex>
      )}

      {isLoading && page > 1 && (
        <Flex height="100px" alignItems="center" justifyContent="center">
          <Loader />
        </Flex>
      )}
      {!isLoading && !!reviews?.length && page < pages && (
        <ShowMoreButton
          design="secondary"
          size="extraSmall"
          width="fit-content"
          alignSelf="center"
          onClick={onShowMore}
        >
          {t('organisation.rating.showMore')}
        </ShowMoreButton>
      )}
      <FullImage isOpen={isOpen} src={image} onClose={onClose} />
    </ReviewsWrapper>
  );
};

export default Reviews;
