import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ListRegionCode } from '@beauty/beauty-market-ui';
import { regionListCode } from '../../../../constants';
import { FormikInput } from '../formik-input/FormikInput';
import { PhoneWrapper } from './style';

const FormikPhone = ({
  codeId,
  phoneId,
  codeName = '',
  phoneName = '',
  placeholder = '',
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { setFieldValue, values, touched, errors } = useFormikContext();

  return (
    <PhoneWrapper {...rest}>
      <ListRegionCode
        design="white"
        backgroudColor="white"
        id={codeId}
        name={codeName || codeId}
        options={regionListCode}
        currentRegionCode={values && codeId ? values[codeId] : ''}
        handleSelect={async (code: string) => setFieldValue(codeId, code)}
        placeholder={t('profile.region')}
        disabled={disabled}
      />
      <FormikInput
        width="100%"
        design="white"
        id={phoneId}
        name={phoneName || phoneId}
        invalidType={touched[phoneId] && errors[phoneId]}
        placeholder={placeholder || t('profile.phone')}
        disabled={disabled}
      />
    </PhoneWrapper>
  );
};

export { FormikPhone };
