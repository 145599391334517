import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scroller, Element } from 'react-scroll';
import { Flex, Div, H5, BodySmall, DataList, Separator } from '@beauty/beauty-market-ui';
import { textAlign } from '../../helpers/rtl';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectOrganisation, setSelectedService } from '../../store/redux-slices/organisationSlice';
import { updateBooking, selectUser } from '../../store/redux-slices/userSlice';
import { CategoryType, ServiceType, SpecialRequirementType } from '../../types';
import Categorizer from './components/Categorizer';
import ServicesSidebar from './components/ServicesSidebar';
import { Container, StyledFlex } from './style';

interface ServicesProps {
  offersCategories: CategoryType[];
  organisationName: string;
  services?: ServiceType[];
  specialRequirement?: SpecialRequirementType;
}

const Services = ({ offersCategories, specialRequirement, organisationName }: ServicesProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { booking } = useAppSelector(selectUser);
  const { selectedService } = useAppSelector(selectOrganisation);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [selectedServiceId, setSelectedServiceId] = useState<string>('');

  const offersCount = useMemo(
    () => offersCategories.reduce((accumulator, currentValue) => accumulator + currentValue.offersCount, 0),
    [offersCategories],
  );

  const categories = useMemo(
    () => [
      {
        id: '0',
        name: t('organisation.about.all'),
        offersCount,
      },
      ...offersCategories,
    ],
    [offersCategories, offersCount, t],
  );

  const onServiceBook = useCallback(
    (service?: ServiceType | null) => {
      if (booking.service?.id === service?.id) {
        dispatch(updateBooking({ service: null, timeslot: null }));
      } else {
        dispatch(updateBooking({ service: service || selectedService, timeslot: null }));
      }
    },
    [dispatch, booking],
  );

  const onServiceInfo = (service: ServiceType) => {
    dispatch(setSelectedService(service));
    setSelectedServiceId(service.id);
    setIsSidebarOpen(prevValue => !prevValue);
  };

  const mapServices: (service: ServiceType) => JSX.Element = service => (
    <Element name={`service-${service.id}`} key={service.id}>
      <DataList
        title={service.title}
        label={service.label}
        description={service.description}
        oldPrice={service.oldPrice}
        price={service.price}
        onLabelClick={() => onServiceInfo(service)}
        onCheckboxClick={() => onServiceBook(service)}
        defaultChecked={booking.service?.id === service.id}
      />
    </Element>
  );

  const groups = offersCategories.map((group: CategoryType) => (
    <StyledFlex flexDirection="column" key={group.id} name={`category-${group.id}`}>
      <H5>{group.name}</H5>
      <BodySmall mt="8px" mb="24px">
        {group.description}
      </BodySmall>
      {group.services?.map(mapServices)}
      <Separator marginBottom={3} />
    </StyledFlex>
  ));

  useEffect(() => {
    booking?.service?.id &&
      scroller.scrollTo(`service-${booking?.service?.id}`, {
        duration: 250,
        delay: 0,
        smooth: true,
        containerId: 'services',
      });
  }, [booking?.service?.id]);

  return (
    <Flex marginTop="20px" flexDirection="column" width="100%">
      <Categorizer categories={categories} />

      <Container id="services">
        {specialRequirement ? (
          <Flex flexDirection="column" mb="24px" name="category-0">
            <H5>{specialRequirement.title}</H5>
            <BodySmall marginTop="8px">{specialRequirement.text}</BodySmall>
          </Flex>
        ) : (
          <Div name="category-0" />
        )}
        {groups}
      </Container>

      {isSidebarOpen && (
        <ServicesSidebar
          setIsOpen={setIsSidebarOpen}
          organisationName={organisationName}
          serviceId={selectedServiceId}
          isServiceSelected={booking.service?.id === selectedService?.id}
          onSubmitButton={onServiceBook}
        />
      )}
    </Flex>
  );
};

export default Services;
