import { useTranslation } from 'react-i18next';
import { Button, CloseIcon, getMinWidthMediaQuery, useMediaQuery, BottomSheet, Flex } from '@beauty/beauty-market-ui';
import { BookingPopup } from '../../../components';
import { getSelectedLanguage } from '../../../constants';
import { flexDirection } from '../../../helpers/rtl';
import { getTranslation } from '../../../helpers/utils';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { TextWithTranslations } from '../../../types';
import SelectedService from './SelectedService';

interface PopupProps {
  onClosePopup: () => void;
  onButtonClick: () => void;
  disabled?: boolean;
}

const Popup = ({ onClosePopup, onButtonClick, disabled }: PopupProps) => {
  const { booking } = useAppSelector(selectUser);
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const primaryButton = (
    <Button size="large" width="290px" onClick={onButtonClick}>
      {t('organisation.booking.dateOfVisit')}
    </Button>
  );

  const secondaryButton = (
    <Button design="secondary" onClick={onClosePopup}>
      <CloseIcon height="22" width="22" />
    </Button>
  );

  const content = booking.service && (
    <SelectedService
      title={booking.service.label}
      description={getTranslation(booking.service.description as TextWithTranslations, language)}
      oldPrice={booking.service.oldPrice}
      price={booking.service.price}
    />
  );

  return isLarge ? (
    <BookingPopup
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      content={content}
      disabled={disabled}
    />
  ) : (
    <BottomSheet
      content={content}
      isOpen={onClosePopup}
      detent="content-height"
      FooterBody={
        <Flex width="100%" justifyContent="space-between">
          <Button design="secondary" onClick={onClosePopup}>
            <CloseIcon height="22" width="22" />
          </Button>
          <Button size="large" width="290px" onClick={onButtonClick}>
            {t('organisation.booking.dateOfVisit')}
          </Button>
        </Flex>
      }
      onClose={onClosePopup}
      handleClose={onClosePopup}
    />
  );
};

export default Popup;
